<!--
 * @Description: Excel导入修改专员
 * @Author: 琢磨先生
 * @Date: 2024-01-18 10:22:18
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-18 11:44:34
-->
<template>
  <el-button type="primary" link icon="Right" size="small" @click="onOpen"
    >导入修改专员</el-button
  >
  <el-dialog
    title="导入修改专员"
    v-model="visible"
    width="800"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-alert type="info" :closable="false">
      <div class="flex-row item-center">
        请按格式编辑Excel文件后上传、只支持xlsx文件。
        <el-link type="primary" :underline="false" @click="onDownTemplate"
          >下载</el-link
        >文件格式模板
      </div>
    </el-alert>
    <div style="flex-grow: 1">
      <el-upload
        ref="uploadRef"
        class="upload-demo"
        :auto-upload="false"
        :http-request="upload"
        accept=".xls, .xlsx"
        :limit="1"
        drag
        action
      >
        <el-icon class="el-icon--upload">
          <upload-filled />
        </el-icon>
        <div class="el-upload__text">
          拖拽到此或者
          <em>点击选择</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">请选择xlsx文件</div>
        </template>
      </el-upload>
    </div>

    <template #footer>
      <el-button @click="visible = false" :disabled="uploading">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="uploading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import fileDownload from "js-file-download";

export default {
  data() {
    return {
      visible: false,
      form: {},
      uploading: false,
    };
  },
  emits: ["success"],
  methods: {
    onOpen() {
      this.visible = true;
    },

    /**
     * 下载模板
     */
    onDownTemplate() {
      this.$http
        .get("admin/v1/company/zy/export_temp", {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "导入修改专员-模板.xlsx");
        })
        .finally(() => {});
    },

/**
     * 上传文件导入
     */
     upload(ctx) {
      this.uploading = true;
      var formData = new FormData();
      formData.append("file", ctx.file);
      this.$http
        .post(`admin/v1/company/zy/excel_import`, formData)
        .then((res) => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$emit("success");
            this.$refs.uploadRef.clearFiles();
          }
        })
        .finally(() => {
          this.uploading = false;
        });
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.uploadRef.submit();
    },
  },
};
</script>

<style scoped></style>
